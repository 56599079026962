import {
    ROLE_SUCCESS,
    ROLE_FAIL,
    SET_MESSAGE,
    ROLE_PENDING,
    ADD_ROLE_SUCCESS,
    ADD_ROLE_FAIL,
    ADD_ROLE_PENDING,
    UPDATE_ROLE_SUCCESS,
    UPDATE_ROLE_FAIL,
    UPDATE_ROLE_PENDING,

} from "../types";
import { toast } from "react-toastify";
import UserService from "../../services/user.service";


export const getRoles = () => async(dispatch) => {
    dispatch({
        type: ROLE_PENDING,
        payload: { roles: "isLoading" },
    });
    return await UserService.getRoles().then(
        (data) => {
            data.data.status ?
                dispatch({
                    type: ROLE_SUCCESS,
                    payload: { roles: data.data.result },
                }) :
                dispatch({
                    type: ROLE_FAIL,
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: ROLE_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        return Promise.reject();
    });
};

//add role
export const addRoles = (body) => async(dispatch) => {
    dispatch({
        type: ADD_ROLE_PENDING,
        payload: { addRoles: "isLoading" },
    });
    return await UserService.addRole(body).then(
        (data) => {
            data.data.status ? toast.success("Role added Successfully !", {autoClose: 2000 }): toast.error(data.data.message, {autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: ADD_ROLE_SUCCESS,
                    payload: { addRoles : data.data.status },
                }) :
                dispatch({
                    type: ADD_ROLE_FAIL,
                    payload: { addRoles : data.data.status },
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: ADD_ROLE_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
    }).finally(()=>{
        dispatch({
          type: ADD_ROLE_FAIL,
        });
      });
};
// Update roles 
export const updateRoles = (body) => async(dispatch) => {
    dispatch({
        type: UPDATE_ROLE_PENDING,
        payload: { updateRole: "isLoading" },
    });
    return await UserService.updateRole(body).then(
        (data) => {
            data.data.status ? toast.success("Role updated Successfully !", {autoClose: 2000 }): toast.error(data.data.message, {autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: UPDATE_ROLE_SUCCESS,
                    payload: { updateRole: data.data.status},
                })
             :
                dispatch({
                    type: UPDATE_ROLE_FAIL,
                    payload: { updateRole: data.data.status},
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: UPDATE_ROLE_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
    }).finally(()=>{
        dispatch({
          type: UPDATE_ROLE_FAIL,
        });
      });
};