import logo from "../../assets/images/logofooter.png";
import taskIcon from "../../assets/images/Icon=Tasks.svg";
import taskRedIcon from "../../assets/images/task_red_icon.svg";
import manageIcon from "../../assets/images/Icon=Manage.svg";
import billingIcon from "../../assets/images/Icon=Billing.svg";
import invoiceIcon from "../../assets/images/Icon=Invoice.svg";
import locateIcon from "../../assets/images/Icon=Locate.svg";
import employeesIcon from "../../assets/images/Icon=Employee.svg";
import departmentIcon from "../../assets/images/Icon=Departments.svg";
import designationIcon from "../../assets/images/Icon=Designations.svg";
import employeeStatusIcon from "../../assets/images/Icon=Employee Status.svg";
import officeLocationIcon from "../../assets/images/Icon=Office Locations.svg";
import permissionIcon from "../../assets/images/Icon=Permissions.svg";
import productIcon from "../../assets/images/Icon=Products.svg";
import rolesIcon from "../../assets/images/Icon=Roles.svg";
import employeeIcon from "../../assets/images/employee_icon.svg";
import productBlueIcon from "../../assets/images/product__blue_icon.svg";
import storeIcon from "../../assets/images/Icon=Stores.svg";
import storeTypeIcon from "../../assets/images/Icon=Store Types.svg";
import stockIcon from "../../assets/images/Icon=Stocks.svg";
import unitTypeIcon from "../../assets/images/Icon=Unit Types.svg";
import dashboardIcon from "../../assets/images/Icon=Dashboard.svg";
import taskQueueIcon from "../../assets/images/Icon=Task List.svg";
import teamIcon from "../../assets/images/Icon=Team.svg";
import doctorIcon from "../../assets/images/Icon=Doctors.svg";
import doctorRankIcon from "../../assets/images/Icon=Doctor Ranks.svg";
import doctorSpecializationIcon from "../../assets/images/Icon=Doctor Specialization.svg";
import practiceLocationIcon from "../../assets/images/Icon=Practice Locations.svg";
import subTasksIcon from "../../assets/images/Icon=Subtasks.svg";
import taskStatusIcon from "../../assets/images/Icon=Task Statuses.svg";
import taskPriorityIcon from "../../assets/images/Icon=Task Priorities.svg";
import cityIcon from "../../assets/images/Icon=Cities.svg";
import countryIcon from "../../assets/images/Icon=Countries.svg";
import Menu from "../../assets/images/Menu.svg";
import taskTargetIcon from "../../assets/images/Icon=Target.svg";
import approvalIcon from "../../assets/images/Icon=Approvals.svg";
import legacyIcon from "../../assets/images/Icon=Legacy.svg";
import MenuSidebar from "../../assets/images/Menu-sidebar.svg";
import MenuCloseSidebar from "../../assets/images/Button.svg";
import person_icon from "../../assets/images/person_icon.png";
import practiceLocationTypeIcon from "../../assets/images/Icon=Practice Location Type.svg";
import taskTypeIcon from "../../assets/images/Icon=Task Type.svg"
import CustomerRank from "../../assets/images/rank.svg"
import Forms_icon from "../../assets/images/forms_icon.svg"



const SidebarIcons = {
  logo,
  CustomerRank,
  MenuSidebar,
  MenuCloseSidebar,
  taskIcon,
  taskRedIcon,
  manageIcon,
  billingIcon,
  invoiceIcon,
  locateIcon,
  employeesIcon,
  departmentIcon,
  designationIcon,
  employeeStatusIcon,
  officeLocationIcon,
  permissionIcon,
  productIcon,
  rolesIcon,
  employeeIcon,
  productBlueIcon,
  storeIcon,
  storeTypeIcon,
  stockIcon,
  unitTypeIcon,
  dashboardIcon,
  taskQueueIcon,
  teamIcon,
  doctorIcon,
  doctorRankIcon,
  doctorSpecializationIcon,
  practiceLocationIcon,
  subTasksIcon,
  taskStatusIcon,
  taskPriorityIcon,
  cityIcon,
  countryIcon,
  Menu,
  taskTargetIcon,
  approvalIcon,
  legacyIcon,
  person_icon,
  practiceLocationTypeIcon,
  taskTypeIcon,
  Forms_icon,
};

export default SidebarIcons;