import "./Linechart.css";
import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
} from "recharts";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Loader from "../Loader";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Button, IconButton, Menu, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getProducts } from "../../../actions/userActions/productAction";
import { getEmployeeSubOrdinates, } from "../../../actions/userActions/employeeAction";
import { getCallAveragEmployee } from "../../../actions/userActions/callAverageEmp";
import { Tooltip as MaterialTooltip } from "@mui/material";
import ApplyButton from "../buttons/ApplyButton";
import ResetButton from "../buttons/ResetButton";


export function Linechart({callEmployee,onChangeCallEmployee}) {
  const [appliedFilters, setAppliedFilters] = useState([]);
  const currentDate = moment();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [customDate, setCustomDate] = useState(false);
  const employees = useSelector((state) => state.employees_reducer.employeeSubordinates);
  const user = JSON.parse(localStorage.getItem("user"));
  const employeeId =
    user.result[0] !== undefined ? user.result[0].user.id : false;
  const dispatch = useDispatch();
  const [uniqueProducts, setUniqueProducts] = useState([]);
  const callAvgEmployee = useSelector(
    (state) => state.call_avg_emp_reducer.callAvgEmp
  );
  const [formError, setFormError] = useState({});
  function choosefilters(filters) {
    setAppliedFilters(filters);
  }
  const [Filters, setFilters] = useState({
    productIds: [],
    employee: 0,
    fromDate: moment(currentDate).subtract(7, "days").format("YYYY-MM-DD"),
    toDate: moment(currentDate).format("YYYY-MM-DD"),
  });
  const [selected, setSelected] = useState({
    productIds: [],
    employee: "",
    fromDate: moment(currentDate).subtract(7, "days").format("YYYY-MM-DD"),
    toDate: moment(currentDate).format("YYYY-MM-DD"),
  });
  const [productsName, setProductsName] = useState([]);
  const products = useSelector((state) => state.products_reducer.products);
  const [productColors, setProductColors] = useState({});

  const setProductNames = (array) => {
    setProductsName(array);
  };

  useEffect(() => {
    let data = [];
    products.products &&
      products !== "isLoading" &&
      products.products.map((row) => {
        appliedFilters.map((row2) => {
          if (row.id == row2) {
            data.push(row.name);
          }
        });
      });
    setProductNames(data);
  }, [products, appliedFilters]);

  // push data in array for rechart
  const [data, setData] = useState([]);

  useEffect(() => {
    if (
      callAvgEmployee &&
      callAvgEmployee !== "isLoading" &&
      callAvgEmployee.byEmployee &&
      callAvgEmployee.byEmployee.length > 0
    ) {
      const uniqueProducts = [];
      const groupedData = callAvgEmployee.byEmployee.reduce((result, item) => {
        const date = moment(item.dateTime).format("D MMM, YY");
        const key = `${date}`;

        if (!result[key]) {
          result[key] = { date };
        }

        if (!uniqueProducts.includes(item.productName)) {
          uniqueProducts.push(item.productName);
        }

        if (!result[key][item.productName]) {
          result[key][item.productName] = item.calls;
        } else {
          result[key][item.productName] += item.calls;
        }

        return result;
      }, {});

      const productNames = new Set();
      Object.values(groupedData).forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (key !== "date") {
            productNames.add(key);
          }
        });
      });
      const outputData = Object.values(groupedData).map((item) => {
        const formattedItem = {
          date: item.date,
        };
        productNames.forEach((productName) => {
          formattedItem[productName] = item[productName] || 0;
        });
        return formattedItem;
      }).sort((a, b) => moment(a.date, "D MMM, YY").toDate() - moment(b.date, "D MMM, YY").toDate());

      setData(outputData);
      setUniqueProducts(uniqueProducts);
    }
  }, [callAvgEmployee]);

  function getRandomColor() {
    const minChannelValue = 50;
    const maxChannelValue = 200;
    const randomRed = Math.floor(Math.random() * (maxChannelValue - minChannelValue + 1)) + minChannelValue;
    const randomGreen = Math.floor(Math.random() * (maxChannelValue - minChannelValue + 1)) + minChannelValue;
    const randomBlue = Math.floor(Math.random() * (maxChannelValue - minChannelValue + 1)) + minChannelValue;
    return `rgb(${randomRed}, ${randomGreen}, ${randomBlue})`;
  }
  //
  const productBody = {
    filter: {
      textSearch: "",
    },
    pageNumber: -1,
    pageSize: 0,
  };
  useEffect(() => {
    if (open) {
      dispatch(getProducts(productBody))
        .then(() => {
          dispatch(getEmployeeSubOrdinates(employeeId));
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });

    }

  }, [open]);
  const handleApply = () => {
    if (validateForm()) {
      // body for CallAvragEmployee
      const body = {
        employeeFilters: {
          productIds: Filters.productIds,
          employee: Filters.employee,
        },
        startTime: Filters.fromDate,
        endTime: Filters.toDate,
      };
      onChangeCallEmployee(body)
      dispatch(getCallAveragEmployee(body));
      choosefilters(Filters.productIds);
      handleClose();
    }
  };
  const handleReset = () => {
    setSelected({
      productIds: [],
      employee: 0,
      fromDate: moment(currentDate).subtract(7, "days").format("YYYY-MM-DD"),
      toDate: moment(currentDate).format("YYYY-MM-DD"),
    });
    setFilters({
      productIds: [],
      employee: 0,
      fromDate: moment(currentDate).subtract(7, "days").format("YYYY-MM-DD"),
      toDate: moment(currentDate).format("YYYY-MM-DD"),
    });
    setSelectedButton("last 7 days");
  }
  //
  const validateForm = () => {
    let errors = {};
    let isValid = true;
    if (!Filters.fromDate) {
      errors.fromDate = "From date is required.";
      isValid = false;
    }
    if (!Filters.toDate) {
      errors.toDate = "To date is required.";
      isValid = false;
    }

    setFormError(errors);
    return isValid;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectedButton, setSelectedButton] = useState("last 7 days");

  const handleToggleSelection = (event, newSelection) => {
    if (newSelection === "last 7 days") {
      setCustomDate(false);
      const fromDate = moment(currentDate).subtract(7, "days").format("YYYY-MM-DD");
      const toDate = moment(currentDate).format("YYYY-MM-DD");
      setSelected(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
      setFilters(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
    } else if (newSelection === "today") {
      setCustomDate(false);
      const currentDate = moment().toDate();
      setSelected(prevValues => ({ ...prevValues, fromDate: currentDate, toDate: currentDate }));
      setFilters(prevValues => ({ ...prevValues, fromDate: currentDate, toDate: currentDate }));
    } else if (newSelection === "yesterday") {
      setCustomDate(false);
      const yesterdayDate = moment().subtract(1, "days").toDate();
      setSelected(prevValues => ({ ...prevValues, fromDate: yesterdayDate, toDate: yesterdayDate }));
      setFilters(prevValues => ({ ...prevValues, fromDate: yesterdayDate, toDate: yesterdayDate }));
    } else if (newSelection === "last 30 days") {
      setCustomDate(false);
      const fromDate = moment().subtract(29, "days").toDate();
      const toDate = moment().toDate();
      setSelected(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
      setFilters(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
    } else if (newSelection === "this month") {
      setCustomDate(false);
      const fromDate = moment().startOf("month").toDate();
      const toDate = moment().endOf("month").toDate();
      setSelected(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
      setFilters(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
    } else if (newSelection === "last month") {
      setCustomDate(false);
      const fromDate = moment().subtract(1, "month").startOf("month").toDate();
      const toDate = moment().subtract(1, "month").endOf("month").toDate();
      setSelected(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
      setFilters(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
    } else if (newSelection === "last year") {
      setCustomDate(false);
      const fromDate = moment().subtract(1, "year").startOf("year").toDate();
      const toDate = moment().subtract(1, "year").endOf("year").toDate();
      setSelected(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
      setFilters(prevValues => ({ ...prevValues, fromDate: fromDate, toDate: toDate }));
    } else if (newSelection === "custom") {
      setCustomDate(true);
      setSelected(prevValues => ({ ...prevValues, fromDate: "", toDate: "" }));
      setFilters(prevValues => ({ ...prevValues, fromDate: "", toDate: "" }));
    }

    setSelectedButton(newSelection);
  };
  const handleEmployeeChange = (event) => {
    setSelected(prevValues => ({
      ...prevValues,
      employee: event.target.value,
    }));
    setFilters(prevValues => ({
      ...prevValues,
      employee: event.target.value,
    }));
  };

  const handleProductsChange = (event, selectedProducts) => {
    const selectedProductsIds = selectedProducts.map(Product => Product.id);
    setSelected(prevValues => ({
      ...prevValues,
      productIds: selectedProducts,
    }));
    setFilters(prevValues => ({
      ...prevValues,
      productIds: selectedProductsIds,
    }));
  };
  const handleFromDateChange = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    setSelected(prevValues => ({
      ...prevValues,
      fromDate: formattedDate,
    }));
    setFilters(prevValues => ({
      ...prevValues,
      fromDate: formattedDate,
    }));
  };
  const handleToDateChange = (date) => {
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    setSelected(prevValues => ({
      ...prevValues,
      toDate: formattedDate,
    }));
    setFilters(prevValues => ({
      ...prevValues,
      toDate: formattedDate,
    }));
  };
  useEffect(() => {
    if (uniqueProducts.length > 0) {
      const newProductColors = {};
      uniqueProducts.forEach((product) => {
        if (!productColors[product]) {
          newProductColors[product] = getRandomColor();
        }
      });
      setProductColors((prevColors) => ({ ...prevColors, ...newProductColors }));
    }
  }, [uniqueProducts]);

  return (
    <>
      <div className="chart-container" style={{ position: "relative", maxHeight: '400px', padding: '24px', boxShadow: "0px 2px 10px 0px #0000001A" }}>
        <div>
          <div className="row" style={{ height: '50px', }}>
            <div className="col-10" style={{ height: "20px" }}>
              <p style={{
                fontFamily: "Roboto",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "23px",
                textAlign: "left",
                color: "#000000"
              }}>Call average by Employee</p>
            </div>
            <div className="col-2 d-flex justify-content-end">
              <MaterialTooltip title="Apply Filter" arrow enterDelay={500} placement="bottom">
              <IconButton>
                                 <FilterAltOutlinedIcon onClick={handleClick}/>
                                </IconButton>
              </MaterialTooltip>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                PaperProps={{
                  style: {
                    width: "989px",
                    borderRadius: "4px",
                    background: "#FFFFFF",
                    boxShadow: "0px 11px 15px -7px #00000033",
                  },
                }}
              >
                <div className="row" style={{
                  padding: "16px 24px 16px 24px",
                  justify: "space-between",
                  display: "flex",
                  gap: "24px"
                }}>
                  <div className="col-12 d-flex ml-2">
                    <h6 style={{
                      fontFamily: "Roboto",
                      fontSize: "20px",
                      fontWeight: "500",
                      lineHeight: "32px",
                      textAlign: "left",
                      color: "#000000DE"
                    }}>
                      Filter Results
                    </h6>
                    <MaterialTooltip title="Close Filter" arrow enterDelay={500}>
                      <CloseIcon style={{ marginLeft: "auto", cursor: "pointer" }} onClick={handleClose} />
                    </MaterialTooltip>
                  </div>
                </div>
                <div className="row" style={{
                  gap: "24px",
                  borderBottom: "1px solid #0000001F",
                  padding: "24px 16px 24px 16px",
                  alignItems: "center"
                }}>
                  <div className="row" style={{ gap: "16px" }}>
                    <div className="col-12 d-flex justify-content-center align-items-center" style={{ height: "48px" }}>
                      <ToggleButtonGroup
                        exclusive
                        value={selectedButton}
                        onChange={handleToggleSelection}
                        aria-label="button group"
                      >
                        <ToggleButton
                          value="today"
                          style={{
                            width: "114px",
                            height: "32px",
                            padding: "4px 8px 4px 8px",
                            gap: "8px",
                            borderRadius: "4px",
                            textTransform: "none",
                            backgroundColor: selectedButton === "today" ? "#2F54EB" : "inherit",
                            color: selectedButton === "today" ? "#fff" : "inherit"
                          }}
                        >
                          Today
                        </ToggleButton>
                        <ToggleButton
                          value="yesterday"
                          style={{
                            width: "114px",
                            height: "32px",
                            padding: "4px 8px 4px 8px",
                            gap: "8px",
                            borderRadius: "4px",
                            textTransform: "none",
                            backgroundColor: selectedButton === "yesterday" ? "#2F54EB" : "inherit",
                            color: selectedButton === "yesterday" ? "#fff" : "inherit"
                          }}
                        >
                          Yesterday
                        </ToggleButton>
                        <ToggleButton
                          value="last 7 days"
                          style={{
                            width: "114px",
                            height: "32px",
                            padding: "4px 8px 4px 8px",
                            gap: "8px",
                            borderRadius: "4px",
                            textTransform: "none",
                            backgroundColor: selectedButton === "last 7 days" ? "#2F54EB" : "inherit",
                            color: selectedButton === "last 7 days" ? "#fff" : "inherit"
                          }}
                        >
                          Last 7 days
                        </ToggleButton>
                        <ToggleButton
                          value="last 30 days"
                          style={{
                            width: "114px",
                            height: "32px",
                            padding: "4px 8px 4px 8px",
                            gap: "8px",
                            borderRadius: "4px",
                            textTransform: "none",
                            backgroundColor: selectedButton === "last 30 days" ? "#2F54EB" : "inherit",
                            color: selectedButton === "last 30 days" ? "#fff" : "inherit"
                          }}
                        >
                          Last 30 days
                        </ToggleButton>
                        <ToggleButton
                          value="this month"
                          style={{
                            width: "114px",
                            height: "32px",
                            padding: "4px 8px 4px 8px",
                            gap: "8px",
                            borderRadius: "4px",
                            textTransform: "none",
                            backgroundColor: selectedButton === "this month" ? "#2F54EB" : "inherit",
                            color: selectedButton === "this month" ? "#fff" : "inherit"
                          }}
                        >
                          This Month
                        </ToggleButton>
                        <ToggleButton
                          value="last month"
                          style={{
                            width: "114px",
                            height: "32px",
                            padding: "4px 8px 4px 8px",
                            gap: "8px",
                            borderRadius: "4px",
                            textTransform: "none",
                            backgroundColor: selectedButton === "last month" ? "#2F54EB" : "inherit",
                            color: selectedButton === "last month" ? "#fff" : "inherit"
                          }}
                        >
                          Last Month
                        </ToggleButton>
                        <ToggleButton
                          value="last year"
                          style={{
                            width: "114px",
                            height: "32px",
                            padding: "4px 8px 4px 8px",
                            gap: "8px",
                            borderRadius: "4px",
                            textTransform: "none",
                            backgroundColor: selectedButton === "last year" ? "#2F54EB" : "inherit",
                            color: selectedButton === "last year" ? "#fff" : "inherit"
                          }}
                        >
                          Last Year
                        </ToggleButton>
                        <ToggleButton
                          value="custom"
                          style={{
                            width: "114px",
                            height: "32px",
                            padding: "4px 8px 4px 8px",
                            gap: "8px",
                            borderRadius: "4px",
                            textTransform: "none",
                            backgroundColor: selectedButton === "custom" ? "#2F54EB" : "inherit",
                            color: selectedButton === "custom" ? "#fff" : "inherit"
                          }}
                        >
                          Custom
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                    <div className="col-12 d-flex" style={{
                      height: "56px",
                    }}>
                      <div className="col-6">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="From"
                            valueType="date"
                            value={selected.fromDate && selected.fromDate.length !== 0 ? dayjs(selected.fromDate) : null}
                            onChange={handleFromDateChange}
                            disabled={!customDate}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                            sx={{ width: "100%" }}
                          />
                        </LocalizationProvider>
                        {formError.fromDate && (
                          <span style={{ color: "red" }}>{formError.fromDate}</span>
                        )}
                      </div>
                      <div className="col-6">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="To"
                            valueType="date"
                            value={selected.toDate && selected.toDate.length != 0 ? dayjs(selected.toDate) : null}
                            onChange={handleToDateChange}
                            disabled={!customDate}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                            sx={{ width: "100%" }}
                          />
                        </LocalizationProvider>
                        {formError.toDate && (
                          <span style={{ color: "red" }}>{formError.toDate}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ gap: "16px" }}>
                    <div className="col-12 d-flex" >
                      <div className="col-6">
                        <Autocomplete
                          multiple
                          limitTags={2}
                          id="multiple-limit-tags-employee"
                          onChange={handleProductsChange}
                          value={selected.productIds}
                          menuprops={{ PaperProps: { style: { maxHeight: 200, width: 250 } } }}
                          options={products === "isLoading"
                            ? [{ name: "Loading...", id: -1 }]
                            : products.length === 0
                              ? [{ name: "No data", id: -1 }]
                              : products[0].products
                                .filter(product => product.active)
                                .sort((a, b) => a.name.localeCompare(b.name))
                          }
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField {...params} label="Product" />
                          )}
                        />
                      </div>
                      <div className="col-6">
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Employee</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selected.employee}
                            label="Employee"
                            onChange={handleEmployeeChange}
                            MenuProps={{ PaperProps: { style: { maxHeight: 160 } } }}
                          >
                            {employees === "isLoading" && (
                              <MenuItem disabled>
                                Loading...
                              </MenuItem>
                            )}

                            {employees !== "isLoading" && employees.length === 0 && (
                              <MenuItem disabled>
                                No data
                              </MenuItem>
                            )}

                            {employees !== "isLoading" && employees.length !== 0 && employees
                              .sort((a, b) => {
                                const firstNameComparison = a.firstName.localeCompare(b.firstName);
                                if (firstNameComparison !== 0) {
                                  return firstNameComparison;
                                }
                                return a.lastName.localeCompare(b.lastName);
                              })
                              .map(employee => (
                                <MenuItem key={employee.id} value={employee.id}>
                                  {employee.firstName + " " + employee.lastName}
                                </MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mr-4" style={{
                  padding: "16px 24px 16px 24px",
                  gap: "8px",
                  display: "flex",
                  justifyContent: "flex-end"
                }}>
                  <ApplyButton onClick={handleApply} />
                  <ResetButton onClick={handleReset} />
                </div>

              </Menu>
            </div>

            <div className="row">
              <div className="col-12" style={{ height: '30px' }}>
                <p style={{ margin: "8px", maxHeight: '24px', fontFamily: "inter", fontWeight: "500", fontSize: "16px", lineHeight: "24px" }}>
                  {callAvgEmployee.byEmployee?.[0]?.employeeName}
                </p>
              </div>
            </div>

            {/* <div className="row" style={{marginTop: '8px'}}>
              <div className="col-12 d-flex" style={{width: '480px', height: '24px'}}>
                {productsName &&
                  productsName.map((pn) => <span className="mr-2" style={{paddingLeft: '4px', paddingBottom: '3px', paddingRight: '4px', borderRadius: '100px', border: '1px solid #00000014', height: '24px', width: ""}}  key={pn}>{pn}</span>)}
              </div>
            </div> */}
          </div>
        </div>

        <div className="graph-container" style={{ height: '320px' }}>
          {callAvgEmployee === "isLoading" && (<div className="col-12 d-flex justify-content-center"><Loader colored={true} /></div>)}
          {callAvgEmployee !== "isLoading" &&
            callAvgEmployee.byEmployee &&
            callAvgEmployee.byEmployee !== undefined &&
            callAvgEmployee.byEmployee.length > 0 && (
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={data}
                  margin={{
                    top: 24,
                    right: 10,
                    left: 2,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" tick={{ angle: -45, textAnchor: 'end' }} height={70} />
                  <YAxis>
                    <Label
                      value="Number of Calls"
                      angle={-90}
                      position="insideLeft"
                      style={{ textAnchor: 'middle' }}
                    />
                  </YAxis>
                  <Tooltip />
                  <Legend />
                  {uniqueProducts.map((product, index) => (
                    <Line
                      key={index}
                      type="monotone"
                      dataKey={product}
                      stroke={productColors[product]}
                      activeDot={{ r: 8 }}
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            )}
          {!callAvgEmployee || (callAvgEmployee === "isLoading") ||
            (callAvgEmployee.length === 0 ||
              (callAvgEmployee.byEmployee && callAvgEmployee.byEmployee.length === 0)) && (
              <div style={{ height: "240px", width: "480", border: "1px solid grey", display: "flex", alignItems: "center", justifyContent: "center", margin: "24px 10px 5px 20px" }}>
                <div className="col-12 d-flex justify-content-center">
                  No Data to show "Apply or change Filters"
                </div>
              </div>
            )}
        </div>
      </div>
    </>
  );
}
