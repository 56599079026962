import userService from "../../services/user.service";
import { ARCHIVE_TASK_FAIL, ARCHIVE_TASK_PENDING, ARCHIVE_TASK_SUCCESS } from "../types";

export const archiveTask = (body) => async(dispatch) => {
    dispatch({
        type: ARCHIVE_TASK_PENDING,
        payload: { archiveTask: "isLoading" },
    });
    return await userService.taskArchive(body).then(
        (data) => {
            
            data.data.status ?
                dispatch({
                    type: ARCHIVE_TASK_SUCCESS,
                    payload: { archiveTask: data.data },
                }) :
                dispatch({
                    type: ARCHIVE_TASK_FAIL,
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: ARCHIVE_TASK_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        return Promise.reject();
    });
};