import React from 'react'
import './Forms.css'
import Download_icon from '../../assets/images/Download.svg'


const SingleForm = ({formImage, name}) => {
  return (
    <>
   <div className="form-section d-flex flex-column p-5 justify-content-center align-items-center">
  <div className="p-2"><img src={formImage} alt="" /></div>
  <img src={Download_icon} alt="Download" class="download-icon" />
  <p className="p-2 text-center">{name}</p>

</div>


    </>
  )
}

export default SingleForm