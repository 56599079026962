import axios from "axios";
import AuthService from "./auth/auth.service";
import authService from "./auth/auth.service";

export const interceptor = () => {
  return axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      console.log(error, "data in error");
      // Do something with response error
      if (error.response.status === 401) {
        const user = JSON.parse(localStorage.getItem("user"));
        if(user === null || !user){
          handleLogout();
        }
        // console.log(user, "user in interceptor");
        const body = {
          employeeId: user.result[0].user.id,
          // accessToken: user.result[0].accessToken,
          refreshToken: user.result[0].refreshToken,
          deviceId:authService.getDeviceID()
        };
        localStorage.removeItem("user");
        return AuthService.refreshToken(body)
          .then((response) => {
            if (response.data) {
              localStorage.setItem("user", JSON.stringify(response.data));

              // Modifying the original request to include the new access token
              error.config.headers[
                "Authorization"
              ] = `Bearer ${response.data.result[0].accessToken}`;

              // Retrying the original API request
              return axios(error.config);
            }
          })
          .catch((error) => {
            console.log(error);
            handleLogout();
            return error;
          });
      }
    }
  );
};
const handleLogout = () => {
  // logout
  localStorage.removeItem("user");
  localStorage.removeItem("path");
  localStorage.removeItem("fcmPosted");
  localStorage.removeItem("permissionsList");
  // Optional: Redirect to login page or home page after logout
  window.location.href = '/';
};