import { ARCHIVE_TASK_FAIL, ARCHIVE_TASK_PENDING, ARCHIVE_TASK_SUCCESS } from "../../actions/types";

const initialState = { 
    archiveTask: [],
    };

const archive_task_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ARCHIVE_TASK_SUCCESS:
            return {
                ...state,
                archiveTask: payload.archiveTask,
            };
        case ARCHIVE_TASK_PENDING:
            return {
                ...state,
                archiveTask: payload.archiveTask,
            };
        case ARCHIVE_TASK_FAIL:
            return {
                ...state,
                archiveTask: []
            };
        default:
            return state;
    }
}
export default archive_task_reducer