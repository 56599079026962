import { GENERATE_REPORT_FAIL, GENERATE_REPORT_PENDING, GENERATE_REPORT_SUCCESS } from "../../actions/types";

const initialState = { 
    report: [],
    };

    const generate_Report = (state = initialState, action) => {
        const { type, payload } = action;
        switch (type) {
            case GENERATE_REPORT_SUCCESS:
                return {
                    ...state,
                    report: payload.report,
                };
            case GENERATE_REPORT_PENDING:
                return {
                    ...state,
                    report: payload.report,
                };
            case GENERATE_REPORT_FAIL:
                return {
                    ...state,
                    report: []
                };
            default:
                return state;
        }
    }
    export default generate_Report