import {
    SUB_TASK_SUCCESS,
    SUB_TASK_FAIL,
    SET_MESSAGE,
    SUB_TASK_PENDING,
    ADD_SUB_TASK_SUCCESS,
    ADD_SUB_TASK_FAIL,
    ADD_SUB_TASK_PENDING,
    UPDATE_SUB_TASK_SUCCESS,
    UPDATE_SUB_TASK_FAIL,
    UPDATE_SUB_TASK_PENDING
} from "../types";
import { toast } from "react-toastify";
import UserService from "../../services/user.service";


export const getSubTasks = () => async(dispatch) => {
    dispatch({
        type: SUB_TASK_PENDING,
        payload: { subTasks: "isLoading" },
    });
    return await UserService.getSubTasks().then(
        (data) => {
            data.data.status ?
                dispatch({
                    type: SUB_TASK_SUCCESS,
                    payload: { subTasks : data.data.result },
                }) :
                dispatch({
                    type: SUB_TASK_FAIL,
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: SUB_TASK_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    });
};

//add Sub Tasks
export const addSubTasks = (body) => async(dispatch) => {
    dispatch({
        type: ADD_SUB_TASK_PENDING,
        payload: { addSubTasks: "isLoading" },
    });
    return await UserService.addSubTask(body).then(
        (data) => {
            data.data.status ? toast.success("Sub Task added Successfully !", {autoClose: 2000 }): toast.error(data.data.message, {autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: ADD_SUB_TASK_SUCCESS,
                    payload: { addSubTasks : data.data.status },
                }) :
                dispatch({
                    type: ADD_SUB_TASK_FAIL,
                    payload: { addSubTasks : data.data.status },
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: ADD_SUB_TASK_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    }).finally(()=>{
        dispatch({
          type: ADD_SUB_TASK_FAIL
        })
      });
};

//update Sub Tasks
export const updateSubTasks = (body) => async(dispatch) => {
    dispatch({
        type: UPDATE_SUB_TASK_PENDING,
        payload: { updateSubTasks: "isLoading" },
    });
    return await UserService.updateSubTask(body).then(
        (data) => {
            data.data.status ? toast.success("Sub Task updated Successfully !", {autoClose: 2000 }): toast.error(data.data.message, {autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: UPDATE_SUB_TASK_SUCCESS,
                    payload: { updateSubTasks : data.data.status },
                }) :
                dispatch({
                    type: UPDATE_SUB_TASK_FAIL,
                    payload: { updateSubTasks : data.data.status },
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: UPDATE_SUB_TASK_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    }).finally(()=>{
        dispatch({
          type: UPDATE_SUB_TASK_FAIL
        })
      });
};