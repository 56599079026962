import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addTaskPriorities, updateTaskPriorities } from '../../actions/userActions/taskPriorityAction'
import Loader from '../subComponents/Loader'
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { SketchPicker } from 'react-color';
import TextField from "@mui/material/TextField";
import { SaveButton } from "../subComponents/buttons/SaveButton";
import { CancelButton } from "../subComponents/buttons/CancelButton";
import { createTheme } from '@mui/material/styles';
import { ColorPicker } from 'mui-color';
import '../dashboard/Dashboard.css'
import HrComponent from '../reuse_components/HrComponent';
function AddTaskPriorities() {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const initialValues = { name: "", description: "", active: false, color: "#ffff" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
      if (location.taskPriorities) {
        setFormValues({
          ...formValues,
          name: location.taskPriorities.row.name,
          description: location.taskPriorities.row.description,
          color: location.taskPriorities.row.color,
          id: location.id,
          active: location.taskPriorities.row.active,
        });
      }
    }, [location]);

    const addTaskPriorityHandle = (e) => {
      e.preventDefault();
      const errors = validate(formValues);
      setFormErrors(errors);
  
      if (Object.keys(errors).length === 0) {
        if (location.taskPriorities) {
          dispatch(updateTaskPriorities(formValues));
        } else {
          dispatch(addTaskPriorities(formValues));
        }
      }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        if (name === "active" && formValues.active === true) {
            setFormValues({ ...formValues, [name]: false });
        }
        else if (name === "active" && formValues.active === false) {
            setFormValues({ ...formValues, [name]: true });
        }
    }
  
    
    const handleChangeComplete = (color) => {
        setFormValues({ ...formValues, color: color.hex });
    };
    console.log(formValues, "formValues");
    const validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = "Name is required!"
        }
        if (!values.description) {
            errors.description = "Description is required!"
        }
        if (!values.color) {
            errors.color = "Colour is required!"
        }
        return errors;
    }
    const addTaskPriority = useSelector(state => state.task_priorities_reducer.addTaskPriorities);
    const updateTaskPriority = useSelector(state => state.task_priorities_reducer.updateTaskPriorities);
    const permissions = JSON.parse(localStorage.getItem("permissionsList"));
    const handleGoBack = () => {
        history.goBack();
    };
    return (
      <>
        {addTaskPriority && addTaskPriority !== "isLoading" && (
          <Redirect to="/admin/taskPriorities" />
        )}
        {updateTaskPriority && updateTaskPriority != "isLoading" && <Redirect to="/admin/taskPriorities" />}
        {!location.taskPriorities && <Redirect to="/admin/AddTaskPriorities" />}
        {!permissions.includes("CanAddTaskPriority") && (
          <Redirect to="/admin" />
        )}
        {!permissions.includes("CanUpdateTaskPriority") && (
          <Redirect to="/admin" />
        )}
        <div>
          <div className="row">
            <div className="col-md-6">
              <h2 className="d-inine-block float-left">
                <i
                  onClick={history.goBack}
                  className="fas fa-arrow-left"
                  aria-hidden="true"
                  style={{ cursor: "pointer" }}
                />{" "}
                {location.taskPriorities ? "Update Priority" : "Add Priority"}
              </h2>
            </div>
           <HrComponent />
            <div className="col-md-6"></div>
          </div>
          <div className="row">
            <div className="col-md-12 mt-5">
              <TextField
                style={{ width: "100%", fontSize: "46px" }}
                className="first_textfield"
                id="outlined-controlled"
                label="Name *"
                name="name"
                value={formValues.name}
                onChange={handleChange}
              />
              <div style={{ color: "red" }}>{formErrors.name}</div>
            </div>

            <div className="col-md-12 mt-5">
              <TextField
                style={{ width: "100%" }}
                multiline
                rows={3}
                value={formValues.description}
                name="description"
                onChange={handleChange}
                label="Description *"
              />
              <div style={{ color: "red" }}>{formErrors.description}</div>
            </div>
            <div className="col-md-12 mt-5">
              <label className="form-control-label">Color *</label> <br />
              {/* <ColorPicker
                color={formValues.color}
                onClick={(e)=>{
                    console.log(e);
                }}
                style={{ alignItems: "center !important" }}
              /> */}
         <SketchPicker
                            color={formValues.color}
                            onChangeComplete={handleChangeComplete}
                        />
                        <div style={{ color: 'red' }}>{formErrors.color}</div>
            </div>

            <div className="col-md-12 mt-5">
              <div
                className="form-group"
                style={{ display: "flex", alignItems: "flex-start" }}
              >
                <input
                  name="active"
                  style={{ cursor: "pointer", height: "16px", width: "16px" }}
                  type="checkbox"
                  checked={formValues.active}
                  className="ml-1 mt-1"
                  onChange={handleChange}
                />
                <label
                  style={{ paddingLeft: "10px" }}
                  className="form-control-label"
                >
                  Is this priority active?
                </label>
              </div>
            </div>
            <div
              className="col-lg-12"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "20px",
              }}
            >
              <CancelButton onClick={handleGoBack} />
              <SaveButton
                onClick={addTaskPriorityHandle}
                isLoading={
                  addTaskPriority === "isLoading" ||
                  updateTaskPriority === "isLoading"
                }
                text={location.taskPriorities ? "Update" : "Save"}
              />
            </div>
          </div>
        </div>
      </>
    );
}

export default AddTaskPriorities
