import React from "react";
import { logout } from "../../actions/auth/auth";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import "./Header.css";
import logo from "../../assets/images/Logo.svg";
import Notification_logo from "../../assets/images/notification-bing.svg"
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link, useHistory } from 'react-router-dom'
const Header = (props) => {
  const [age, setAge] = React.useState('');
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));

  const rowData = {
    id:
      user.result[0].user.id
  }
  const logoutHandle = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
if(user && user?.result)
    dispatch(logout(user.result[0]?.user.id,""));
  };
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <>
      {user.status ? (
        <div className="bg-12 d-flex align-items-center justify-content-between flex-d ">
          <div className="pl-4">
            <a className="navbar-brand" href="#">
              <img src={logo} alt="Logo" style={{ width: "128px", height: "40px" }} />
            </a>
          </div>
          <nav className="navbar navbar-expand-sm navbar-light float-right mr-4">
            <ul className="navbar-nav">
              <li className="nav-item dropdown" style={{ display: "flex", gap: "30px" }}>
                {/* <a className="nav-link dropdown-toggle p-0" href="#" id="navbardrop" data-toggle="dropdown">
                Dropdown link
              </a> */}
                {/* <img src={Notification_logo} alt="" /> */}
                <div

                  className="header-avatar-wrapper"
                  id="navbardrop"
                  data-toggle="dropdown"
                  style={{ display: "flex", gap: "12px", alignItems: "center" }}
                >


                  <div style={{ height: "40px", width: "40px" }}>
                    <p>
                      {user.result[0].user.firstName.charAt(0)}
                      {user.result[0].user.lastName.charAt(0)}
                    </p>

                  </div>
                  <span >
                    <p className="name_font">
                      {user.result[0].user.firstName +
                        " " +
                        user.result[0].user.lastName}
                    </p>
                    <p className=" designation_font">{user.result[0].user.designation}</p>
                  </span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8" fill="none">
                    <path d="M14.6 1.45703L9.16669 6.89037C8.52502 7.53203 7.47503 7.53203 6.83336 6.89037L1.40002 1.45703" stroke="#505157" stroke-width="1.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>



                <div className="dropdown-menu avatar-dropdown-menu bg-12">
                  <div style={{ display: "flex", flexDirection: "column" }} className="box">
                    <Link to={{
                      pathname: "/admin/Profile",
                      state: { rowData: rowData },
                    }}>

                      <div className="profile_section">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M12 6C13.1 6 14 6.9 14 8C14 9.1 13.1 10 12 10C10.9 10 10 9.1 10 8C10 6.9 10.9 6 12 6ZM12 16C14.7 16 17.8 17.29 18 18H6C6.23 17.28 9.31 16 12 16ZM12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z" fill="#505157" />
                        </svg>
                        <p style={{ color: "var(--grey-700, var(--grey-700, #131316))", paddingLeft: "7px" }}>Profile</p>
                      </div>
                    </Link>
                    <div className="line_hr"></div>
                    <div className="logout_section">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M17 7L15.59 8.41L18.17 11H8V13H18.17L15.59 15.58L17 17L22 12L17 7ZM4 5H12V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H12V19H4V5Z" fill="#505157" />
                      </svg>
                      <button
                        onClick={logoutHandle}
                        style={{ background: "none" }}
                      >

                        <span>Sign out</span>
                      </button>
                    </div>
                  </div>

                </div>
              </li>
            </ul>
          </nav>
          {props.children}
        </div>
      ) : (
        <>
          <Redirect to="/" />
        </>
      )}
    </>
  );
};

export default Header;
