import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  addDoctors,
  updateDoctors,
} from '../../actions/userActions/doctorAction'
import Loader from '../subComponents/Loader'
import { getCities } from '../../actions/userActions/cityAction'
import { getDoctorRanks } from '../../actions/userActions/doctorRankAction'
import { getDoctorSpecializations } from '../../actions/userActions/doctorSpecializationAction'
import { Redirect } from 'react-router-dom'
import { useLocation, useHistory } from 'react-router-dom'
import { getCustomerRank } from '../../actions/userActions/customerRankAction'
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CancelButton from '../subComponents/buttons/CancelButton'
import SaveButton from '../subComponents/buttons/SaveButton'
import { CircularProgress } from '@mui/material'
import HrComponent from '../reuse_components/HrComponent'
function AddDoctors() {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const initialValues = {
    name: '',
    pmdcnumber: '',
    specialization: '',
    rank: '',
    customerRankId: '',
    contact1: '',
    contact2: '',
    city: '',
    email: '',
    active: false
  }

  const [page, setPage] = useState(1)

  const [cityFilters, setCityFilters] = useState({
    textSearch: '',
    countryId: '1',
  })
  const cityBody = {
    filter: cityFilters,
    pageNumber: -1,
    pageSize: 0,
  }
  const [loading, setLoading] = useState(true); // Loading state
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getCities(cityBody));
        await dispatch(getDoctorRanks());
        await dispatch(getDoctorSpecializations());
        await dispatch(getCustomerRank(-1));
        
        if (location.doctors) {
          setFormValues({
            ...formValues,
            name: location.doctors.row.name,
            pmdcnumber: location.doctors.row.pmdcnumber,
            specialization: location.doctors.row.specialization,
            rank: location.doctors.row.rank,
            customerRankId: location.doctors.row.customerRankId,
            contact1: location.doctors.row.contact1,
            contact2: location.doctors.row.contact2,
            city: location.doctors.row.city,
            id: location.id,
            email: location.doctors.row.email?.trim(),
            active: location.doctors.row.active,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchData();
  }, []);

  const addSubmitHandle = async (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      if (location.doctors) {
        dispatch(updateDoctors(formValues));
      } else {
        dispatch(addDoctors(formValues));
      }
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = name === 'email' ? value.trim() : value;

    if (name === "active") {
      setFormValues((prevValues) => ({ ...prevValues, [name]: !prevValues.active }));
    } else {
      setFormValues((prevValues) => ({ ...prevValues, [name]: trimmedValue }));
    }
  };

  const validate = (values) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const errors = {}
    if (!values.name) {
      errors.name = 'Name is required!'
    }
    if (values.specialization === '') {
      errors.specialization = 'Specialization is required!'
    }
    if (values.customerRankId === '') {
      errors.customerRankId = 'Customer rank is required!'
    }
    if (values.rank === '') {
      errors.rank = 'Rank is required!'
    }  
    if (values.email && values.email.length > 0 && !emailRegex.test(values.email.trim())) {
      errors.email = 'Invalid email!';
    }

    if (values.city === '') {
      errors.city = 'City is required!'
    }
    return errors
  }

  const allCities = useSelector((state) => state.cities_reducer.cities);
  const ranks = useSelector((state) => state.doctor_rank_reducer.ranks);
  const specializations = useSelector(
    (state) => state.doctor_specialization_reducer.specializations
  );
  const addDoctor = useSelector((state) => state.doctors_reducer.addDoctors);
  const updateDoctor = useSelector((state) => state.doctors_reducer.updateDoctors);
  const permissions = JSON.parse(localStorage.getItem('permissionsList'));
  const customerRanks = useSelector((state) => state.customer_rank_reducer.customerRank);
  const handleGoBack = () => {
    history.goBack();
  };
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }}>
        <CircularProgress size={60} style={{ color: '#002D72' }} />
      </div>
    );
  }
  return (
    <>
    {addDoctor && addDoctor !== "isLoading" && (
        <Redirect to="/admin/doctors" />
      )}
      {updateDoctor && updateDoctor != "isLoading" && <Redirect to="/admin/doctors" />}
      {!permissions.includes('CanAddDoctor') && <Redirect to="/admin" />}
      {!permissions.includes('CanUpdateDoctor') && <Redirect to="/admin" />}
      {!location.doctors && <Redirect to={'/admin/AddDoctors'} />}
      <div>
        <div className="row">
          <div className="col-md-6">
            <h2 className="d-inine-block float-left">
              <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              />{" "}
              {location.doctors ? "Update Doctor" : "Add Doctor"}
            </h2>
          </div>
        <HrComponent />
          <div className="col-md-6"></div>
        </div>
        <div className="row">
          <div className='col-6 mt-5'>
            <TextField
              style={{ width: "100%", fontSize: "46px" }}
              className="first_textfield"
              id="outlined-controlled"
              label="Doctor Name *"
              name="name"
              value={formValues.name}
              onChange={handleChange}
            />
            <div style={{ color: "red" }}>{formErrors.name}</div>
          </div>
          <div className='col-6 mt-5'>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">City *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formValues.city}
                onChange={handleChange}
                label="City *"
                name='city'
                MenuProps={{ PaperProps: { style: { maxHeight: 210 } } }}
              >
                {allCities === "isLoading" && (
                  <MenuItem disabled>
                    Loading...
                  </MenuItem>
                )}

                {allCities !== "isLoading" && allCities.length === 0 && (
                  <MenuItem disabled>
                    No data
                  </MenuItem>
                )}

                {allCities !== "isLoading" && allCities.length !== 0 && allCities[0].cities
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(city => (
                    <MenuItem key={city.id} value={city.id}>
                      {city.name}
                    </MenuItem>
                  ))
                }
              </Select>
              <div style={{ color: 'red' }}>{formErrors.city}</div>
            </FormControl>
          </div>
          <div className='col-4 mt-5'>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label"> Customer Rank *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formValues.customerRankId}
                onChange={handleChange}
                label=" Customer Rank *"
                name='customerRankId'
                MenuProps={{ PaperProps: { style: { maxHeight: 210 } } }}
              >
                {customerRanks === "isLoading" && (
                  <MenuItem disabled>
                    Loading...
                  </MenuItem>
                )}

                {customerRanks !== "isLoading" && customerRanks.length === 0 && (
                  <MenuItem disabled>
                    No data
                  </MenuItem>
                )}

                {customerRanks !== "isLoading" && customerRanks.length !== 0 && customerRanks.result
                  .map(customerRank => (
                    <MenuItem key={customerRank.customerRankId} value={customerRank.customerRankId}>
                      {customerRank.rankDescription}
                    </MenuItem>
                  ))
                }
              </Select>
              <div style={{ color: 'red' }}>{formErrors.customerRankId}</div>
            </FormControl>
          </div>
          <div className='col-4 mt-5'>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Specialization *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formValues.specialization}
                onChange={handleChange}
                label="Specialization *"
                name='specialization'
                MenuProps={{ PaperProps: { style: { maxHeight: 210 } } }}
              >
                {specializations === "isLoading" && (
                  <MenuItem disabled>
                    Loading...
                  </MenuItem>
                )}

                {specializations !== "isLoading" && specializations.length === 0 && (
                  <MenuItem disabled>
                    No data
                  </MenuItem>
                )}

                {specializations !== "isLoading" && specializations.length !== 0 && specializations
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(specialization => (
                    <MenuItem key={specialization.id} value={specialization.id}>
                      {specialization.name}
                    </MenuItem>
                  ))
                }
              </Select>
              <div style={{ color: 'red' }}>{formErrors.specialization}</div>
            </FormControl>
          </div>
          <div className='col-4 mt-5'>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Designation *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formValues.rank}
                onChange={handleChange}
                label="Designation *"
                name='rank'
                MenuProps={{ PaperProps: { style: { maxHeight: 210 } } }}
              >
                {ranks === "isLoading" && (
                  <MenuItem disabled>
                    Loading...
                  </MenuItem>
                )}

                {ranks !== "isLoading" && ranks.length === 0 && (
                  <MenuItem disabled>
                    No data
                  </MenuItem>
                )}

                {ranks !== "isLoading" && ranks.length !== 0 && ranks
                  .filter(rank => rank.active)
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(rank => (
                    <MenuItem key={rank.id} value={rank.id}>
                      {rank.name}
                    </MenuItem>
                  ))
                }
              </Select>
              <div style={{ color: 'red' }}>{formErrors.rank}</div>
            </FormControl>
          </div>
          <div className='col-4 mt-5'>
            <TextField
              style={{ width: "100%", fontSize: "46px" }}
              className="first_textfield"
              id="outlined-controlled"
              label="Contact 1 *"
              name="contact1"
              value={formValues.contact1}
              onChange={handleChange}
            />
            <div style={{ color: "red" }}>{formErrors.contact1}</div>
          </div>
          <div className='col-4 mt-5'>
            <TextField
              style={{ width: "100%", fontSize: "46px" }}
              className="first_textfield"
              id="outlined-controlled"
              label="Contact 2"
              name="contact2"
              value={formValues.contact2}
              onChange={handleChange}
            />
            <div style={{ color: "red" }}>{formErrors.contact2}</div>
          </div>
          <div className='col-4 mt-5'>
            <TextField
              style={{ width: "100%", fontSize: "46px" }}
              className="first_textfield"
              id="outlined-controlled"
              label="Email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
            />
            <div style={{ color: "red" }}>{formErrors.email}</div>
          </div>
          <div className="form-group mt-5" style={{ display: "flex", alignItems: "flex-start" }}>
            <input
              name="active"
              style={{ cursor: "pointer", height: "16px", width: "16px" }}
              type="checkbox"
              checked={formValues.active}
              className="ml-2 mt-1"
              onChange={handleChange}
            />
            <label
              style={{ paddingLeft: "10px", }}
              className="form-control-label"
            >
              Is this doctor active?
            </label>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
          <CancelButton
            onClick={handleGoBack}
          />
          <SaveButton
            onClick={addSubmitHandle}
            isLoading={addDoctor === "isLoading" || updateDoctor === "isLoading"}
            text={location.doctors ? "Update" : "Save"}
          />
        </div>
      </div>
    </>
  )
}

export default AddDoctors
