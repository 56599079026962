import React from 'react'
import SingleForm from './SingleForm'
import Excel_icon from '../../assets/images/Excel_icon.svg'
import Leave_app from '../../assets/images/leave_app.svg'
import HrComponent from '../reuse_components/HrComponent'

const Forms = () => {
  return (
    <>
      <div className="row">
          <div className="col-12">
            <h2 className="d-inline-block">Forms</h2>
            <HrComponent />
          </div>
         
        </div>

    <div className="d-flex gap-8">
    <SingleForm formImage= {Excel_icon} name ='Expense Form'/>
    <SingleForm formImage= {Leave_app} name ='Leave Application Form'/>
    <SingleForm formImage= {Leave_app} name ='Medical Form'/>
    <SingleForm formImage= {Leave_app} name ='Health Insurance Form'/>
    <SingleForm formImage= {Leave_app} name ='ANP Form Final'/>
    </div>

    <div className="d-flex gap-8 mt-5">
    <SingleForm formImage= {Leave_app} name ='Clearance Form'/>
    <SingleForm formImage= {Leave_app} name ='Company Financed Vehicle Form'/>
    <SingleForm formImage= {Leave_app} name ='Employee Information Form'/>
    <SingleForm formImage= {Leave_app} name ='Exi Interview Form'/>
    <SingleForm formImage= {Leave_app} name ='Internal Correspondence'/>
    </div>

    <div className="d-flex gap-8 mt-5">
    <SingleForm formImage= {Leave_app} name ='Joining Report Form'/>
    <SingleForm formImage= {Leave_app} name ='Marriage Allowance Form'/>
    <SingleForm formImage= {Leave_app} name ='PF Initiate Form'/>
    <SingleForm formImage= {Leave_app} name ='PF Release Form'/>
    <SingleForm formImage= {Leave_app} name ='Vehicle Handover Form'/>
    </div>
    </>
  )
}

export default Forms