import { combineReducers } from "redux";
import auth from "./auth/auth";
import permissions_reducer from "./userReducers/permissionsReducer";
import employees_reducer from "./userReducers/employeesReducer";
import officeLocations_reducer from "./userReducers/officeLocationsReducer";
import teams_reducer from "./userReducers/teamsReducer";
import products_reducer from "./userReducers/productsReducer";
import designations_reducer from "./userReducers/designationsReducer";
import departments_reducer from "./userReducers/departmentsReducer";
import employmentStatuses_reducer from "./userReducers/employmentStatusesReducer";
import cities_reducer from "./userReducers/citiesReducer";
import countries_reducer from "./userReducers/countriesReducer";
import roles_reducer from "./userReducers/rolesReducer";
import doctors_reducer from "./userReducers/doctorsReducer";
import doctors_with_customer_reducer from "./userReducers/doctorWithCustomerReducer";
import customer_rank_reducer from "./userReducers/customeRankReducer";
import call_avg_emp_reducer from "./userReducers/callAverageEmpReducer";
import call_avg_pro_reducer from "./userReducers/callAverageProReducer";
import call_avg_tab_reducer from "./userReducers/callAverageTabReducer";
import call_avg_pract_reducer from "./userReducers/callAveragePractReducer";
import call_avg_tasks_reducer from "./userReducers/callAverageTasksReducer";
import practiceLocations_reducer from "./userReducers/practiceLocationsReducer";
import doctor_specialization_reducer from "./userReducers/doctorSpecializationsReducer";
import doctor_rank_reducer from "./userReducers/doctorRanksReducer";
import sub_tasks_reducer from "./userReducers/subTasksReducer";
import task_priorities_reducer from "./userReducers/taskPrioritiesReducer";
import task_statuses_reducer from "./userReducers/taskStatusesReducer";
import task_types_reducer from "./userReducers/taskTypesReducer";
import tasks_reducer from "./userReducers/tasks/tasksReducer";
import tasks_target_reducer from "./userReducers/tasks/taskTargetReducer";
import stores_reducer from "./userReducers/storesReducer";
import store_types_reducer from "./userReducers/storeTypesReducer";
import stocks_reducer from "./userReducers/stocksReducer";
import unit_types_reducer from "./userReducers/unitTypesReducer";
import message from "./message";
import location_type_reducer from "./userReducers/locationTypesReducer";
import generate_Report from "./userReducers/generateReportReducer";
import archive_task_reducer from "./userReducers/archiveTaskReducer";

export default combineReducers({
  auth,
  archive_task_reducer,
  message,
  generate_Report,
  permissions_reducer,
  employees_reducer,
  officeLocations_reducer,
  teams_reducer,
  products_reducer,
  designations_reducer,
  departments_reducer,
  employmentStatuses_reducer,
  cities_reducer,
  countries_reducer,
  roles_reducer,
  doctors_reducer,
  doctors_with_customer_reducer,
  customer_rank_reducer,
  call_avg_emp_reducer,
  call_avg_pro_reducer,
  practiceLocations_reducer,
  doctor_specialization_reducer,
  doctor_rank_reducer,
  sub_tasks_reducer,
  task_priorities_reducer,
  task_statuses_reducer,
  task_types_reducer,
  tasks_reducer,
  stores_reducer,
  store_types_reducer,
  stocks_reducer,
  unit_types_reducer,
  tasks_target_reducer,
  call_avg_pract_reducer,
  call_avg_tab_reducer,
  call_avg_tasks_reducer,
  location_type_reducer
});
