import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPermissions } from "../../actions/userActions/permissionsAction";
import $ from "jquery";
import "./Sidebar.css";
import { Link } from "react-router-dom";
import {
  TaskExist,
  ManageExist,
  BillingExist,
  InvoiceExist,
} from "./menuArrays";
import { useLocation } from "react-router-dom";
import { logout } from "../../actions/auth/auth";
import SidebarIcons from "./SidebarIcons";
import dashboardIcon from "./SidebarIcons"
// import MenuSidebar from "./SidebarIcons"
// import MenuCloseSidebar from "./SidebarIcons"
// import Permissions from "../permissions/Permissions";

function Sidebar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));
  const [localPermissions, setLocalPermissions] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const employeeId = user.result[0] !== undefined ? user.result[0].user.id : false;

  useEffect(() => {
    !employeeId && dispatch(logout());
    $("body").on("click", ".sidebar-dropdown > a", function () {
      $(".sidebar-submenu").slideUp(200);
      if ($(this).parent().hasClass("active")) {
        $(".sidebar-dropdown").removeClass("active");
        $(this).parent().removeClass("active");
      } else {
        $(".sidebar-dropdown").removeClass("active");
        $(this).next(".sidebar-submenu").slideDown(200);
        $(this).parent().addClass("active");
      }
    });

    $("body").on("click", "#close-sidebar", function () {
      let $pageWrapper = $(".page-wrapper");
      let $sidebarWrapper = $(".sidebar-wrapper");
      let $sidebarIcon = $(".sidebar-menu-title");

      if ($pageWrapper.hasClass("toggled")) {
        $pageWrapper.removeClass("toggled");
        $sidebarWrapper.removeClass("toggled-sideBar");
        $sidebarIcon.addClass("sidebar-display-icon hidden");
        $("body").removeClass("sidebar-open");
        setIsSidebarOpen(false);
      } else {
        $pageWrapper.addClass("toggled");
        $sidebarWrapper.addClass("toggled-sideBar");
        $sidebarIcon.removeClass("sidebar-display-icon hidden");
        $("body").addClass("sidebar-open");
        setIsSidebarOpen(true);
      }
    });


  }, []);
  const logoutHandle = async () => {
    dispatch(logout());
  };

  const path = (path) => {
    if (location.pathname === path) {
      return "active";
    }
  };
  useEffect(() => {
    const permissionsListString = localStorage.getItem('permissionsList');
    if (permissionsListString != null) {
      setLocalPermissions(JSON.parse(permissionsListString));
    }
  }, []);

  const permissions = useSelector(
    (state) => state.permissions_reducer.permissions

  );
  console.log(permissions, "permissions in side bar");
  console.log(localPermissions, "local permissions in side bar");

  return (
    <>
      <nav
        id="sidebar"
        className="sidebar-wrapper sidebar-border-class sidebar-shadow"
        style={{ marginTop: "28px" }}
      >
        <div className="sidebar-content">
          <div className="sidebar-menu">
            <ul className="sidebar-menu-list">
              <li className={`align-items-center gap-3  ${path("/admin")}`}>
                <div className=" align-items-center">
                  <div id="close-sidebar" style={{ paddingTop: "24px" }}>
                    <i
                      style={{
                        paddingLeft: "15px",
                        paddingTop: "24px",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={
                          isSidebarOpen
                            ? SidebarIcons.MenuSidebar
                            : SidebarIcons.MenuCloseSidebar
                        }
                      />
                    </i>
                  </div>
                  <hr style={{ border: "1px solid #E7E7E9" }} />
                </div>
              </li>

              <li
                className={`dashboard-dropdown task-dropdown ${
                  isSidebarOpen ? "" : "no-arrow"
                }`}
              >
                {!isSidebarOpen ? (
                  <div className="d-flex align-item-center justify-content-center">
                    <i>
                      <img
                        className="icon"
                        src={SidebarIcons.dashboardIcon}
                        alt="Dashboard"
                      />
                    </i>
                  </div>
                ) : (
                  " "
                )}
                <li
                  className={`sidebar-menu-title menu-item ${path("/admin")}`}
                >
                  <Link className="d-flex align-items-center" to="/admin">
                    <div className="icon-wrapper">
                      <img
                        className="icon change_color"
                        src={SidebarIcons.dashboardIcon}
                        alt="Dashboard"
                      />
                    </div>
                    <span className="sidebar-menu-title">Dashboard</span>
                  </Link>
                </li>
              </li>

              {TaskExist(permissions, localPermissions) && (
                <li
                  className={`sidebar-dropdown task-dropdown ${
                    isSidebarOpen ? "" : "no-arrow"
                  }`}
                >
                  <Link to={"#"} className="d-flex align-items-center">
                    <i>
                      <img className="icon" src={SidebarIcons.taskIcon} />
                    </i>
                    <span className="sidebar-menu-title">Task</span>
                  </Link>
                  <div className="sidebar-submenu">
                    <ul className="task-ul">
                      {(permissions.includes("CanViewTask") ||
                        localPermissions.includes("CanViewTask")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${
                            path("/admin/tasks") ||
                            path("/admin/AddTasks") ||
                            path("/admin/task/view")
                          }`}
                        >
                          <Link
                            to={"/admin/tasks"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.taskQueueIcon}
                              />
                            </div>
                            <span>Task List</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewTask") ||
                        localPermissions.includes("CanViewTask")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/locate"
                          )}`}
                        >
                          <Link
                            to={"/admin/locate"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.locateIcon}
                              />
                            </div>
                            <span>Locate</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewTaskTarget") ||
                        localPermissions.includes("CanViewTaskTarget")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/TaskTarget"
                          )}`}
                        >
                          <Link
                            to={"/admin/TaskTarget"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.taskTargetIcon}
                              />
                            </div>
                            <span>Task Target</span>
                          </Link>
                        </li>
                      )}


                      
                     
                      {(permissions.includes("CanViewTask") ||
                        localPermissions.includes("CanViewTask")) && (
                          <li className={`sidebar-menu-title menu-item ${path(
                            "/admin/completedtask"
                          )}`}>
                             <Link
                            to={"/admin/completedtask"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.taskTargetIcon}
                              />
                            </div>
                            <span>Archive Task</span>
                          </Link>
                          </li>
                      )}
                    </ul>
                  </div>
                </li>
              )}
              {ManageExist(permissions, localPermissions) && (
                <li
                  className={`sidebar-dropdown manage-dropdown ${
                    isSidebarOpen ? "" : "no-arrow"
                  }`}
                >
                  <Link to={"#"} className="d-flex align-items-center">
                    <div className="icon-wrapper">
                      <img className="icon" src={SidebarIcons.manageIcon} />
                    </div>
                    <span className="sidebar-menu-title">Manage</span>
                  </Link>

                  <div className="sidebar-submenu">
                    <ul>
                      {(permissions.includes("CanViewEmployee") ||
                        localPermissions.includes("CanViewEmployee")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${
                            path("/admin/employees") ||
                            path("/admin/Addemployees") ||
                            path("/admin/UpdateEmployees") ||
                            path("/admin/ViewEmployees")
                          }`}
                        >
                          <Link
                            to={"/admin/employees"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.employeesIcon}
                              />
                            </div>
                            <span>Employee</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewPermission") ||
                        localPermissions.includes("CanViewPermission")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${
                            path("/admin/permissions") ||
                            path("/admin/ViewPermissions") ||
                            path("/admin/AddPermissions") ||
                            path("/admin/UpdatePermissions")
                          }`}
                        >
                          <Link
                            to={"/admin/permissions"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.permissionIcon}
                              />
                            </div>
                            <span>Permission Group</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewOfficeLocation") ||
                        localPermissions.includes("CanViewOfficeLocation")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${
                            path("/admin/officeLocations") ||
                            path("/admin/UpdateOfficeLocations") ||
                            path("/admin/AddOfficeLocations")
                          }`}
                        >
                          <Link
                            to={"/admin/officeLocations"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.officeLocationIcon}
                              />
                            </div>
                            <span>Office Location</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewTeam") ||
                        localPermissions.includes("CanViewTeam")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${
                            path("/admin/teams") ||
                            path("/admin/UpdateTeams") ||
                            path("/admin/AddTeams")
                          }`}
                        >
                          <Link
                            to={"/admin/teams"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.teamIcon}
                              />
                            </div>
                            <span>Team</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewProduct") ||
                        localPermissions.includes("CanViewProduct")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${
                            path("/admin/products") ||
                            path("/admin/UpdateProducts") ||
                            path("/admin/AddProducts")
                          }`}
                        >
                          <Link
                            to={"/admin/products"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.productIcon}
                              />
                            </div>
                            <span>Product</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewDesignation") ||
                        localPermissions.includes("CanViewDesignation")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${
                            path("/admin/designations") ||
                            path("/admin/UpdateDesignations") ||
                            path("/admin/AddDesignations")
                          }`}
                        >
                          <Link
                            to={"/admin/designations"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.designationIcon}
                              />
                            </div>
                            <span>Designation</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewDepartment") ||
                        localPermissions.includes("CanViewDepartment")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${
                            path("/admin/departments") ||
                            path("/admin/UpdateDepartments") ||
                            path("/admin/AddDepartments")
                          }`}
                        >
                          <Link
                            to={"/admin/departments"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.departmentIcon}
                              />
                            </div>
                            <span>Department</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewDoctor") ||
                        localPermissions.includes("CanViewDoctor")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${
                            path("/admin/doctors") ||
                            path("/admin/UpdateDoctors") ||
                            path("/admin/AddDoctors")
                          }`}
                        >
                          <Link
                            to={"/admin/doctors"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.doctorIcon}
                              />
                            </div>
                            <span>Doctor</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewDoctorRank") ||
                        localPermissions.includes("CanViewDoctorRank")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${
                            path("/admin/doctorRanks") ||
                            path("/admin/UpdateDoctorRanks") ||
                            path("/admin/AddDoctorRanks")
                          }`}
                        >
                          <Link
                            to={"/admin/doctorRanks"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.doctorRankIcon}
                              />
                            </div>
                            <span>Doctor Designation</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewDoctorSpecialization") ||
                        localPermissions.includes(
                          "CanViewDoctorSpecialization"
                        )) && (
                        <li
                          className={`sidebar-menu-title menu-item ${
                            path("/admin/doctorSpecializations") ||
                            path("/admin/UpdateDoctorSpecializations") ||
                            path("/admin/AddDoctorSpecializations")
                          }`}
                        >
                          <Link
                            to={"/admin/doctorSpecializations"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.doctorSpecializationIcon}
                              />
                            </div>
                            <span>Doctor Specialization</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewPracticeLocation") ||
                        localPermissions.includes(
                          "CanViewPracticeLocation"
                        )) && (
                        <li
                          className={`sidebar-menu-title menu-item ${
                            path("/admin/practiceLocations") ||
                            path("/admin/UpdatePracticeLocations") ||
                            path("/admin/AddPracticeLocations")
                          }`}
                        >
                          <Link
                            to={"/admin/practiceLocations"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.practiceLocationIcon}
                              />
                            </div>
                            <span>Practice Location</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewPracticeLocation") ||
                        localPermissions.includes(
                          "CanViewPracticeLocation"
                        )) && (
                        <li
                          className={`sidebar-menu-title menu-item ${
                            path("/admin/locationTypes") ||
                            path("/admin/UpdateLocationTypes") ||
                            path("/admin/AddLocationTypes")
                          }`}
                        >
                          <Link
                            to={"/admin/locationTypes"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.practiceLocationTypeIcon}
                              />
                            </div>
                            <span>Practice Location Type</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewEmploymentStatus") ||
                        localPermissions.includes(
                          "CanViewEmploymentStatus"
                        )) && (
                        <li
                          className={`sidebar-menu-title menu-item ${
                            path("/admin/employmentStatuses") ||
                            path("/admin/UpdateEmploymentStatuses") ||
                            path("/admin/AddEmploymentStatuses")
                          }`}
                        >
                          <Link
                            to={"/admin/employmentStatuses"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.employeeStatusIcon}
                              />
                            </div>
                            <span>Employment Status</span>
                          </Link>
                        </li>
                      )}

                      {(permissions.includes("CanViewCustomerRank") ||
                        localPermissions.includes("CanViewCustomerRank")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${
                            path("/admin/CustomerRank") ||
                            path("/admin/UpdateCustomerRank") ||
                            path("/admin/AddCustomerRank")
                          }`}
                        >
                          <Link
                            to={"/admin/CustomerRank"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.CustomerRank}
                              />
                            </div>
                            <span>Customer Rank</span>
                          </Link>
                        </li>
                      )}

                      {(permissions.includes("CanViewSubTask") ||
                        localPermissions.includes("CanViewSubTask")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${
                            path("/admin/subTasks") ||
                            path("/admin/UpdateSubTasks") ||
                            path("/admin/AddSubTasks")
                          }`}
                        >
                          <Link
                            to={"/admin/subTasks"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.subTasksIcon}
                              />
                            </div>
                            <span>Sub Task</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewTaskStatus") ||
                        localPermissions.includes("CanViewTaskStatus")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/taskStatuses"
                          )}`}
                        >
                          <Link
                            to={"/admin/taskStatuses"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.taskStatusIcon}
                              />
                            </div>
                            <span>Task Status</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewTaskType") ||
                        localPermissions.includes("CanViewTaskType")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/taskTypes"
                          )}`}
                        >
                          <Link
                            to={"/admin/taskTypes"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.taskTypeIcon}
                              />
                            </div>
                            <span>Task Type</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewTaskPriority") ||
                        localPermissions.includes("CanViewTaskPriority")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${
                            path("/admin/taskPriorities") ||
                            path("/admin/UpdateTaskPriorities") ||
                            path("/admin/AddTaskPriorities")
                          }`}
                        >
                          <Link
                            to={"/admin/taskPriorities"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.taskPriorityIcon}
                              />
                            </div>
                            <span>Task Priority</span>
                          </Link>
                        </li>
                      )}
                      {/* 

                             
                      {permissions.includes("CanViewStock") && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/stocks"
                          )}`}
                        >
                          <Link to={"/admin/stocks"} className="d-flex">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.stockIcon}
                              />
                            </div>
                            <span>Stock</span>
                          </Link>
                        </li>
                      )}
                      {permissions.includes("CanViewStore") && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/stores"
                          )}`}
                        >
                          <Link to={"/admin/stores"} className="d-flex">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.storeIcon}
                              />
                            </div>
                            <span>Store</span>
                          </Link>
                        </li>
                      )}
                      {permissions.includes("CanViewStoreType") && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/storeTypes"
                          )}`}
                        >
                          <Link to={"/admin/storeTypes"} className="d-flex">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.storeTypeIcon}
                              />
                            </div>
                            <span>Store Type</span>
                          </Link>
                        </li>
                      )}
                      {permissions.includes("CanViewUnitType") && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/unitTypes"
                          )}`}
                        >
                          <Link to={"/admin/unitTypes"} className="d-flex">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.unitTypeIcon}
                              />
                            </div>
                            <span>Unit Type</span>
                          </Link>
                        </li>
                      )}


                     
                     */}

                      {(permissions.includes("CanViewCity") ||
                        localPermissions.includes("CanViewCity")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${
                            path("/admin/cities") ||
                            path("/admin/UpdateCities") ||
                            path("/admin/AddCities")
                          }`}
                        >
                          <Link
                            to={"/admin/cities"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.cityIcon}
                              />
                            </div>
                            <span>City</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewCountry") ||
                        localPermissions.includes("CanViewCountry")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${path(
                            "/admin/countries"
                          )}`}
                        >
                          <Link
                            to={"/admin/countries"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.countryIcon}
                              />
                            </div>
                            <span>Country</span>
                          </Link>
                        </li>
                      )}
                      {(permissions.includes("CanViewRole") ||
                        localPermissions.includes("CanViewRole")) && (
                        <li
                          className={`sidebar-menu-title menu-item ${
                            path("/admin/roles") ||
                            path("/admin/UpdateRoles") ||
                            path("/admin/AddRoles")
                          }`}
                        >
                          <Link
                            to={"/admin/roles"}
                            className="d-flex align-items-center"
                          >
                            <div className="icon-wrapper">
                              <img
                                className="icon change_color"
                                src={SidebarIcons.rolesIcon}
                              />
                            </div>
                            <span>Role</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
              )}

              {/* Forms Start  */}

              <li
                className={`sidebar-dropdown task-dropdown ${
                  isSidebarOpen ? "" : "no-arrow"
                }`}
              >
                <ul className="task-ul">
                  <li
                    className={`sidebar-menu-title menu-item ${path(
                      "/admin/forms"
                    )}`}
                  >
                    <Link
                      to={"/admin/forms"}
                      className="d-flex align-items-center"
                    >
                      <div className="icon-wrapper">
                        <img
                          className="icon change_color"
                          src={SidebarIcons.Forms_icon}
                        />
                      </div>
                      <span>Forms</span>
                    </Link>
                  </li>
                </ul>
              </li>

              {/* form section End  */}

              {/* {BillingExist(permissions) && (
                <li className="sidebar-dropdown billing-dropdown">
                  <Link to={"#"} className="d-flex">
                    <div className="icon-wrapper">
                      <img className="icon" src={SidebarIcons.billingIcon} />
                    </div>
                    <span className="sidebar-menu-title">Billing</span>
                  </Link>
                  <div className="sidebar-submenu">
                    <ul>
                      {permissions.includes("CanViewPendingBill") && (
                        <li className={`sidebar-menu-title menu-item`}>
                          <Link to={"#"} className="d-flex">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.billingIcon}
                              />
                            </div>
                            <span>Pending</span>
                          </Link>
                        </li>
                      )}
                      {permissions.includes("CanViewPreviousBill") && (
                        <li className={`sidebar-menu-title menu-item`}>
                          <Link to={"#"} className="d-flex">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.billingIcon}
                              />
                            </div>
                            <span>Previous</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
              )} */}
              {/* {InvoiceExist(permissions) && (
                <li className="sidebar-dropdown invoice-dropdown">
                  <Link to={"#"} className="d-flex">
                    <div className="icon-wrapper">
                      <img className="icon" src={SidebarIcons.invoiceIcon} />
                    </div>
                    <span className="sidebar-menu-title">Invoice</span>
                  </Link>
                  <div className="sidebar-submenu">
                    <ul>
                      {permissions.includes("CanGenerateInvoice") && (
                        <li className={`sidebar-menu-title menu-item`}>
                          <Link to={"#"} className="d-flex">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.invoiceIcon}
                              />
                            </div>
                            <span>New Invoice</span>
                          </Link>
                        </li>
                      )}
                      {permissions.includes("CanUpdateInvoice") && (
                        <li className={`sidebar-menu-title menu-item`}>
                          <Link to={"#"} className="d-flex">
                            <div className="icon-wrapper">
                              <img
                                className="icon"
                                src={SidebarIcons.invoiceIcon}
                              />
                            </div>
                            <span>Update</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
              )} */}
            </ul>
          </div>
          {/* sidebar-menu  */}
        </div>
      </nav>
    </>
  );
}
export default Sidebar;
